<body class="bkg-indigo" style="width: 100%; min-height: 100vh!important">
<app-navbar class="fixed-top"></app-navbar>
<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html lang="es-ES" class="supernova">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
  <link rel="alternate" type="application/json+oembed"
        href="https://www.jotform.com/oembed/?format=json&amp;url=https%3A%2F%2Fform.jotform.com%2F212136949899070"
        title="oEmbed Form">
  <link rel="alternate" type="text/xml+oembed"
        href="https://www.jotform.com/oembed/?format=xml&amp;url=https%3A%2F%2Fform.jotform.com%2F212136949899070"
        title="oEmbed Form">
  <meta property="og:title" content="Formulario RH Interno">
  <meta property="og:url" content="https://form.jotform.com/212136949899070">
  <meta property="og:description" content="Favor pulse el enlace para completar este formulario.">
  <meta name="slack-app-id" content="AHNMASS8M">
  <link rel="shortcut icon" href="./img/H4H/H4H_logo (1).png">
  <link rel="canonical" href="https://form.jotform.com/212136949899070"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=1"/>
  <meta name="HandheldFriendly" content="true"/>
  <title>Formulario RH Interno</title>
  <link href="https://cdn01.jotfor.ms/static/formCss.css?3.3.26836" rel="stylesheet" type="text/css"/>
  <link type="text/css" rel="stylesheet" href="https://cdn02.jotfor.ms/css/styles/nova.css?3.3.26836"/>
  <style type="text/css">@media print {
    .form-section {
      display: inline !important
    }

    .form-pagebreak {
      display: none !important
    }

    .form-section-closed {
      height: auto !important
    }

    .page-section {
      position: initial !important
    }
  }</style>
  <link type="text/css" rel="stylesheet"
        href="https://cdn03.jotfor.ms/themes/CSS/54b929a4700cc4f2408b4567.css?themeRevisionID=60eb6ecddb71c14295405e83"/>
  <link type="text/css" rel="stylesheet"
        href="https://cdn01.jotfor.ms/css/styles/payment/payment_styles.css?3.3.26836"/>
  <link type="text/css" rel="stylesheet"
        href="https://cdn02.jotfor.ms/css/styles/payment/payment_feature.css?3.3.26836"/>
  z

  <style type="text/css">


    .form-label-left {
      width: 150px;
    }

    .form-line {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .form-label-right {
      width: 150px;
    }

    body, html {
      margin: 0;
      padding: 0;
      background: #ffffff;
      font-family: 'Didact Gothic' !important;
      font-size: 18px !important;
    }

    .form-all {
      margin: 0px auto;
      padding-top: 0px;
      width: 700px !important;
      color: #000000 !important;
    }

    @media screen and (max-width: 699px) {
      input[type=text] {
        width: 320px !important;
        height: 41px;
      }

      #input_15 {
        margin-left: 7px!important;
        width: 230px!important;
      }

      #input_16 {
        width: 230px!important;
      }

      #input_35_phone {
        max-width: 30px!important;
      }

      .form-all {

        width: 100% !important;
      }
    }

    @media screen and (max-width: 770px) {
      .form-line[data-type="control_phone"] .form-input > div, .form-line[data-type="control_phone"] .form-input-wide>div {
        display: initial!important;
      }
      .fix-mobile {
        max-width: 200px!important;
      }
    }

  </style>
  <style type="text/css" id="form-designer-style">
    /* Injected CSS Code */
    @import "https://fonts.googleapis.com/css?family=Didact Gothic:light,lightitalic,normal,italic,bold,bolditalic";

    .form-all:after {
      content: "";
      display: table;
      clear: both;
    }

    body, html {
      font-family: 'Didact Gothic' !important;
      font-size: 18px !important;
    }

    .form-all {
      font-family: "Didact Gothic", sans-serif;
    }

    .form-all {
      width: 550px;
    }

    .form-label-left,
    .form-label-right {
      width: 150px;
    }

    .form-label {
      white-space: normal;
    }

    .form-label.form-label-auto {
      display: inline-block;
      float: left;
      text-align: left;
      word-break: break-word;
      width: 150px;
    }

    .form-label-left {
      display: inline-block;
      white-space: normal;
      float: left;
      text-align: left;
    }

    .form-label-right {
      display: inline-block;
      white-space: normal;
      float: left;
      text-align: right;
    }

    .form-label-top {
      white-space: normal;
      display: block;
      float: none;
      text-align: left;
    }

    .form-radio-item label:before {
      top: 0;
    }

    .form-all {
      font-size: 18px;
    }

    .form-label {
      font-weight: bold;
    }

    .form-checkbox-item label,
    .form-radio-item label {
      font-weight: normal;
    }

    .supernova {
      background-color: #000000;
    }

    .supernova body {
      background-color: transparent;
    }

    /*
    @width30: (unit(@formWidth, px) + 60px);
    @width60: (unit(@formWidth, px)+ 120px);
    @width90: (unit(@formWidth, px)+ 180px);
    */
    /* | */
    @media screen and (min-width: 480px) {
      .supernova .form-all {
        border: 1px solid #247eff;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
      }
    }

    /* | */
    /* | */
    @media screen and (max-width: 480px) {
      .jotform-form .form-all {
        margin: 0;
        width: 100%;
      }
    }

    /* | */
    /* | */
    @media screen and (min-width: 480px) and (max-width: 767px) {
      .jotform-form .form-all {
        margin: 0;
        width: 100%;
      }
    }

    /* | */
    /* | */
    @media screen and (min-width: 480px) and (max-width: 549px) {
      .jotform-form .form-all {
        margin: 0;
        width: 100%;
      }
    }

    /* | */
    /* | */
    @media screen and (min-width: 768px) {
      .jotform-form {
        padding: 60px 0;
      }
    }

    /* | */
    /* | */
    @media screen and (max-width: 549px) {
      .jotform-form .form-all {
        margin: 0;
        width: 100%;
      }
    }

    /* | */
    .supernova .form-all,
    .form-all {
      background-color: #ffffff;
      border: 1px solid transparent;
    }

    .form-header-group {
      border-color: #e6e6e6;
    }

    .form-matrix-table tr {
      border-color: #e6e6e6;
    }

    .form-matrix-table tr:nth-child(2n) {
      background-color: #f2f2f2;
    }

    .form-all {
      color: #000000;
    }

    .form-header-group .form-header {
      color: #000000;
    }

    .form-header-group .form-subHeader {
      color: #1a1a1a;
    }

    .form-sub-label {
      color: #1a1a1a;
    }

    .form-label-top,
    .form-label-left,
    .form-label-right,
    .form-html {
      color: #000000;
    }

    .form-checkbox-item label,
    .form-radio-item label {
      color: #1a1a1a;
    }

    .form-line.form-line-active {
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -ms-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -ms-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -ms-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      background-color: rgba(255, 251, 234, 0);
    }

    /* omer */
    .form-radio-item,
    .form-checkbox-item {
      padding-bottom: 6px !important;
    }

    .form-radio-item:last-child,
    .form-checkbox-item:last-child {
      padding-bottom: 0;
    }

    /* omer */
    [data-type="control_radio"] .form-input,
    [data-type="control_checkbox"] .form-input,
    [data-type="control_radio"] .form-input-wide,
    [data-type="control_checkbox"] .form-input-wide {
      width: 100%;
      max-width: 300px;
    }

    .form-radio-item,
    .form-checkbox-item {
      width: 100%;
      max-width: 300px;
      box-sizing: border-box;
    }

    .form-textbox.form-radio-other-input,
    .form-textbox.form-checkbox-other-input {
      width: 80%;
      margin-left: 3%;
      box-sizing: border-box;
    }

    .form-multiple-column {
      width: 100%;
    }

    .form-multiple-column .form-radio-item,
    .form-multiple-column .form-checkbox-item {
      width: 10%;
    }

    .form-multiple-column[data-columncount="1"] .form-radio-item,
    .form-multiple-column[data-columncount="1"] .form-checkbox-item {
      width: 100%;
    }

    .form-multiple-column[data-columncount="2"] .form-radio-item,
    .form-multiple-column[data-columncount="2"] .form-checkbox-item {
      width: 50%;
    }

    .form-multiple-column[data-columncount="3"] .form-radio-item,
    .form-multiple-column[data-columncount="3"] .form-checkbox-item {
      width: 33.33333333%;
    }

    .form-multiple-column[data-columncount="4"] .form-radio-item,
    .form-multiple-column[data-columncount="4"] .form-checkbox-item {
      width: 25%;
    }

    .form-multiple-column[data-columncount="5"] .form-radio-item,
    .form-multiple-column[data-columncount="5"] .form-checkbox-item {
      width: 20%;
    }

    .form-multiple-column[data-columncount="6"] .form-radio-item,
    .form-multiple-column[data-columncount="6"] .form-checkbox-item {
      width: 16.66666667%;
    }

    .form-multiple-column[data-columncount="7"] .form-radio-item,
    .form-multiple-column[data-columncount="7"] .form-checkbox-item {
      width: 14.28571429%;
    }

    .form-multiple-column[data-columncount="8"] .form-radio-item,
    .form-multiple-column[data-columncount="8"] .form-checkbox-item {
      width: 12.5%;
    }

    .form-multiple-column[data-columncount="9"] .form-radio-item,
    .form-multiple-column[data-columncount="9"] .form-checkbox-item {
      width: 11.11111111%;
    }

    .form-single-column .form-checkbox-item,
    .form-single-column .form-radio-item {
      width: 100%;
    }

    .form-checkbox-item .editor-container div,
    .form-radio-item .editor-container div {
      position: relative;
    }

    .form-checkbox-item .editor-container div:before,
    .form-radio-item .editor-container div:before {
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      left: 0;
      width: 24px;
      height: 24px;
    }

    .form-radio-item:not(#foo) {
      position: relative;
    }

    .form-radio-item:not(#foo) .form-radio-other-input,
    .form-radio-item:not(#foo) .form-checkbox-item:not(#foo) .form-checkbox-other-input {
      margin-left: 0;
    }

    .form-radio-item:not(#foo) .form-radio-other.form-radio {
      display: none !important;
    }

    .form-radio-item:not(#foo) input[type="checkbox"],
    .form-radio-item:not(#foo) input[type="radio"] {
      opacity: 0;
      width: 0;
      margin: 0;
      padding: 0;
    }

    .form-radio-item:not(#foo) .form-radio-other,
    .form-radio-item:not(#foo) .form-checkbox-other {
      display: inline-block !important;
      margin-left: 17px;
      margin-right: 13px;
      margin-top: 6px;
    }

    .form-radio-item:not(#foo) .form-checkbox-other-input,
    .form-radio-item:not(#foo) .form-radio-other-input {
      margin: 0;
    }

    .form-radio-item:not(#foo) label {
      line-height: 18px;
      float: left;
      margin-left: 37px;
      outline: none !important;
    }

    .form-radio-item:not(#foo) label:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: baseline;
      margin-right: 4px;
      box-sizing: border-box;
      border-radius: 50%;
      left: 4px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .form-radio-item:not(#foo) label:after {
      content: '';
      position: absolute;
      z-index: 10;
      display: inline-block;
      opacity: 0;
      top: 5px;
      left: 9px;
      width: 8px;
      height: 8px;
    }

    .form-radio-item:not(#foo) input:checked + label:after {
      opacity: 1;
    }

    .form-radio-item:not(#foo) input[type="checkbox"],
    .form-radio-item:not(#foo) input[type="radio"] {
      opacity: 0;
      width: 0;
      margin: 0;
      padding: 0;
    }

    .form-radio-item:not(#foo) .form-radio-other,
    .form-radio-item:not(#foo) .form-checkbox-other {
      display: inline-block !important;
      margin-left: 17px;
      margin-right: 13px;
      margin-top: 6px;
    }

    .form-radio-item:not(#foo) .form-checkbox-other-input,
    .form-radio-item:not(#foo) .form-radio-other-input {
      margin: 0;
    }

    .form-radio-item:not(#foo) label {
      line-height: 24px;
      float: left;
      margin-left: 43px;
      outline: none !important;
    }

    .form-radio-item:not(#foo) label:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: baseline;
      margin-right: 4px;
      box-sizing: border-box;
      border-radius: 50%;
      left: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .form-radio-item:not(#foo) label:after {
      content: '';
      position: absolute;
      z-index: 10;
      display: inline-block;
      opacity: 0;
      top: 7px;
      left: 11px;
      width: 10px;
      height: 10px;
    }

    .form-radio-item:not(#foo) input:checked + label:after {
      opacity: 1;
    }

    .form-radio-item:not(#foo) label:before {
      border: 2px solid #3498db;
    }

    .form-radio-item:not(#foo) label:after {
      background-color: #3498db;
      border-radius: 50%;
      cursor: pointer;
    }

    .form-radio-item:not(#foo) .editor-container div:before {
      content: '';
      border: 2px solid #3498db;
      border-radius: 50%;
      margin: 0 4px 0 -6px;
    }

    .form-checkbox-item:not(#foo) {
      position: relative;
    }

    .form-checkbox-item:not(#foo) label {
      display: block;
    }

    .form-checkbox-item:not(#foo) .form-radio-other-input,
    .form-checkbox-item:not(#foo) .form-checkbox-item:not(#foo) .form-checkbox-other-input {
      margin-left: 0;
    }

    .form-checkbox-item:not(#foo) .form-checkbox-other.form-checkbox {
      display: none !important;
    }

    .form-checkbox-item:not(#foo) input[type="checkbox"],
    .form-checkbox-item:not(#foo) input[type="radio"] {
      opacity: 0;
      width: 0;
      margin: 0;
      padding: 0;
    }

    .form-checkbox-item:not(#foo) .form-radio-other,
    .form-checkbox-item:not(#foo) .form-checkbox-other {
      display: inline-block !important;
      margin-left: 17px;
      margin-right: 13px;
      margin-top: 6px;
    }

    .form-checkbox-item:not(#foo) .form-checkbox-other-input,
    .form-checkbox-item:not(#foo) .form-radio-other-input {
      margin: 0;
    }

    .form-checkbox-item:not(#foo) label {
      line-height: 18px;
      float: left;
      margin-left: 37px;
      outline: none !important;
    }

    .form-checkbox-item:not(#foo) label:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: baseline;
      margin-right: 4px;
      box-sizing: border-box;
      border-radius: 50%;
      left: 4px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .form-checkbox-item:not(#foo) label:after {
      content: '';
      position: absolute;
      z-index: 10;
      display: inline-block;
      opacity: 0;
      top: 8px;
      left: 9px;
      width: 3px;
      height: 3px;
    }

    .form-checkbox-item:not(#foo) input:checked + label:after {
      opacity: 1;
    }

    .form-checkbox-item:not(#foo) label:before {
      border: 2px solid #3498db;
    }

    .form-checkbox-item:not(#foo) label:after {
      background-color: #3498db;
      box-shadow: 0 2px 0 0 #3498db, 2px 2px 0 0 #3498db, 4px 2px 0 0 #3498db, 6px 2px 0 0 #3498db;
      transform: rotate(-45deg);
    }

    .form-checkbox-item:not(#foo) .editor-container div:before {
      content: '';
      border: 2px solid #3498db;
      border-radius: 50%;
      margin: 0 4px 0 -6px;
    }

    .form-checkbox-item:not(#foo) input[type="checkbox"],
    .form-checkbox-item:not(#foo) input[type="radio"] {
      opacity: 0;
      width: 0;
      margin: 0;
      padding: 0;
    }

    .form-checkbox-item:not(#foo) .form-radio-other,
    .form-checkbox-item:not(#foo) .form-checkbox-other {
      display: inline-block !important;
      margin-left: 17px;
      margin-right: 13px;
      margin-top: 6px;
    }

    .form-checkbox-item:not(#foo) .form-checkbox-other-input,
    .form-checkbox-item:not(#foo) .form-radio-other-input {
      margin: 0;
    }

    .form-checkbox-item:not(#foo) label {
      line-height: 24px;
      float: left;
      margin-left: 43px;
      outline: none !important;
    }

    .form-checkbox-item:not(#foo) label:before {
      content: '';
      position: absolute;
      display: inline-block;
      vertical-align: baseline;
      margin-right: 4px;
      box-sizing: border-box;
      border-radius: 50%;
      left: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .form-checkbox-item:not(#foo) label:after {
      content: '';
      position: absolute;
      z-index: 10;
      display: inline-block;
      opacity: 0;
      top: 11px;
      left: 10px;
      width: 4px;
      height: 4px;
    }

    .form-checkbox-item:not(#foo) input:checked + label:after {
      opacity: 1;
    }

    .form-checkbox-item:not(#foo) label:after {
      background-color: #3498db;
      box-shadow: 0 3px 0 0 #3498db, 3px 3px 0 0 #3498db, 6px 3px 0 0 #3498db, 8px 3px 0 0 #3498db;
      transform: rotate(-45deg);
    }

    .supernova {
      height: 100%;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center top;
      background-attachment: fixed;
      background-size: auto;
      background-size: cover;
    }

    .supernova {
      background-image: none;
      background-image: url("./assets/img/FONDO.jpg");
    }

    #stage {
      background-image: none;
      background-image: url("./assets/img/FONDO.jpg");
    }

    /* | */
    .form-all {
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center top;
      background-repeat: repeat;
    }

    .form-header-group {
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center top;
    }

    .form-line {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .form-line {
      padding: 12px 36px;
    }

    .form-all .form-textbox,
    .form-all .form-radio-other-input,
    .form-all .form-checkbox-other-input,
    .form-all .form-captcha input,
    .form-all .form-spinner input,
    .form-all .form-pagebreak-back,
    .form-all .form-pagebreak-next,
    .form-all .qq-upload-button,
    .form-all .form-error-message {
      border-radius: 6px;
    }

    .form-error-message {
      margin-right: 15px !important;
    }

    .form-all .form-sub-label {
      margin-left: 3px;
    }

    .form-all .form-textarea {
      border-radius: 6px;
    }

    .form-all .form-submit-button,
    .form-all .form-submit-reset,
    .form-all .form-submit-print {
      border-radius: 0px;
    }

    .form-all {
      border-radius: 6px;
    }

    .form-section:first-child {
      border-radius: 6px 6px 0 0;
    }

    .form-section:last-child {
      border-radius: 0 0 6px 6px;
    }

    .form-all .qq-upload-button,
    .form-all .form-submit-button,
    .form-all .form-submit-reset,
    .form-all .form-submit-print {
      font-size: 1.15em;
      padding: 12px 18px;
      display: block;
      width: 100%;
      margin: 0;
      font-family: "Didact Gothic", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border: none;
      border-width: 0px !important;
      border-style: solid !important;
      border-color: #31e4a9 !important;
    }

    .form-all .form-buttons-wrapper {
      margin-left: 0 !important;
    }

    .form-all .form-pagebreak-back-container,
    .form-all .form-pagebreak-next-container {
      width: 48% !important;
      padding: 24px 0;
    }

    .form-all .form-pagebreak-next-container {
      margin-left: 4%;
    }

    .form-all .form-submit-print {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .form-all .qq-upload-button,
    .form-all .form-submit-button,
    .form-all .form-submit-reset,
    .form-all .form-submit-print {
      color: #ffffff !important;
      background: #31e4a9;
      box-shadow: none;
      text-shadow: none;
    }

    .form-all .form-pagebreak-back,
    .form-all .form-pagebreak-next {
      font-size: 1em;
      padding: 9px 15px;
      font-family: "Didact Gothic", sans-serif;
      font-size: 18px;
      font-weight: normal;
    }

    /*
    & when ( @buttonFontType = google ) {
      @import (css) "@{buttonFontLink}";
    }
    */
    h2.form-header {
      line-height: 1.618em;
      font-size: 1.714em;
    }

    h2 ~ .form-subHeader {
      line-height: 1.5em;
      font-size: 1.071em;
    }

    .form-header-group {
      text-align: left;
    }

    .form-header-group {
      background-image: url("./assets/img/FONDO.jpg");
    }

    .form-line {
      zoom: 1;
    }

    .form-line:before,
    .form-line:after {
      display: table;
      content: '';
      line-height: 0;
    }

    .form-line:after {
      clear: both;
    }

    .form-captcha input,
    .form-spinner input {
      width: 300px;
    }

    .form-textbox,
    .form-textarea {
      width: 100%;
      max-width: 300px;
      box-sizing: border-box;
    }

    .form-input,
    .form-address-table,
    .form-matrix-table {
      width: 100%;
      max-width: 300px;
    }

    .form-radio-item,
    .form-checkbox-item {
      width: 100%;
      max-width: 300px;
      box-sizing: border-box;
    }

    .form-textbox.form-radio-other-input,
    .form-textbox.form-checkbox-other-input {
      width: 80%;
      margin-left: 3%;
      box-sizing: border-box;
    }

    .form-multiple-column {
      width: 100%;
    }

    .form-multiple-column .form-radio-item,
    .form-multiple-column .form-checkbox-item {
      width: 10%;
    }

    .form-multiple-column[data-columncount="1"] .form-radio-item,
    .form-multiple-column[data-columncount="1"] .form-checkbox-item {
      width: 100%;
    }

    .form-multiple-column[data-columncount="2"] .form-radio-item,
    .form-multiple-column[data-columncount="2"] .form-checkbox-item {
      width: 50%;
    }

    .form-multiple-column[data-columncount="3"] .form-radio-item,
    .form-multiple-column[data-columncount="3"] .form-checkbox-item {
      width: 33.33333333%;
    }

    .form-multiple-column[data-columncount="4"] .form-radio-item,
    .form-multiple-column[data-columncount="4"] .form-checkbox-item {
      width: 25%;
    }

    .form-multiple-column[data-columncount="5"] .form-radio-item,
    .form-multiple-column[data-columncount="5"] .form-checkbox-item {
      width: 20%;
    }

    .form-multiple-column[data-columncount="6"] .form-radio-item,
    .form-multiple-column[data-columncount="6"] .form-checkbox-item {
      width: 16.66666667%;
    }

    .form-multiple-column[data-columncount="7"] .form-radio-item,
    .form-multiple-column[data-columncount="7"] .form-checkbox-item {
      width: 14.28571429%;
    }

    .form-multiple-column[data-columncount="8"] .form-radio-item,
    .form-multiple-column[data-columncount="8"] .form-checkbox-item {
      width: 12.5%;
    }

    .form-multiple-column[data-columncount="9"] .form-radio-item,
    .form-multiple-column[data-columncount="9"] .form-checkbox-item {
      width: 11.11111111%;
    }

    [data-type="control_dropdown"] .form-dropdown {
      width: 100% !important;
      max-width: 300px;
    }

    [data-type="control_fullname"] .form-sub-label-container {
      box-sizing: border-box;
      width: 48%;
    }

    [data-type="control_fullname"] .form-sub-label-container:first-child {
      margin-right: 4%;
    }

    [data-type="control_phone"] .form-sub-label-container {
      width: 62.5%;
      margin-left: 2.5%;
      margin-right: 0;
      float: left;
      position: relative;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child {
      width: 32.5%;
      margin-right: 2.5%;
      margin-left: 0;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child [data-component=areaCode] {
      width: 93%;
      float: left;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child [data-component=areaCode] ~ .form-sub-label {
      display: inline-block;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child .phone-separate {
      position: absolute;
      top: 0;
      right: -16%;
      width: 24%;
      text-align: center;
      text-indent: -4px;
    }

    [data-type="control_birthdate"] .form-sub-label-container {
      width: 22%;
      margin-right: 3%;
    }

    [data-type="control_birthdate"] .form-sub-label-container:first-child {
      width: 50%;
    }

    [data-type="control_birthdate"] .form-sub-label-container:last-child {
      margin-right: 0;
    }

    [data-type="control_birthdate"] .form-sub-label-container .form-dropdown {
      width: 100%;
    }

    [data-type="control_payment"] .form-sub-label-container {
      width: auto;
    }

    [data-type="control_payment"] .form-sub-label-container .form-dropdown {
      width: 100%;
    }

    .form-address-table td .form-dropdown {
      width: 100%;
    }

    .form-address-table td .form-sub-label-container {
      width: 96%;
    }

    .form-address-table td:last-child .form-sub-label-container {
      margin-left: 4%;
    }

    .form-address-table td[colspan="2"] .form-sub-label-container {
      width: 100%;
      margin: 0;
    }

    /*.form-dropdown,
    .form-radio-item,
    .form-checkbox-item,
    .form-radio-other-input,
    .form-checkbox-other-input,*/
    .form-captcha input,
    .form-spinner input,
    .form-error-message {
      padding: 4px 3px 2px 3px;
    }

    .form-header-group {
      font-family: "Didact Gothic", sans-serif;
    }

    .form-section {
      padding: 0px 0px 0px 0px;
    }

    .form-header-group {
      margin: 0px 0px 0px 0px;
    }

    .form-header-group {
      padding: 24px 36px 24px 36px;
    }

    .form-header-group .form-header,
    .form-header-group .form-subHeader {
      color: #ffffff;
    }

    .form-header-group {
      background-color: #31e4a9;
    }

    .form-textbox,
    .form-textarea {
      padding: 4px 3px 2px 3px;
    }

    .form-textbox,
    .form-textarea,
    .form-radio-other-input,
    .form-checkbox-other-input,
    .form-captcha input,
    .form-spinner input {
      background-color: #ffffff;
    }

    .form-textbox,
    .form-textarea {
      width: 100%;
      max-width: 300px;
      box-sizing: border-box;
    }

    [data-type="control_textbox"] .form-input,
    [data-type="control_textarea"] .form-input,
    [data-type="control_fullname"] .form-input,
    [data-type="control_phone"] .form-input,
    [data-type="control_datetime"] .form-input,
    [data-type="control_address"] .form-input,
    [data-type="control_email"] .form-input,
    [data-type="control_passwordbox"] .form-input,
    [data-type="control_autocomp"] .form-input,
    [data-type="control_textbox"] .form-input-wide,
    [data-type="control_textarea"] .form-input-wide,
    [data-type="control_fullname"] .form-input-wide,
    [data-type="control_phone"] .form-input-wide,
    [data-type="control_datetime"] .form-input-wide,
    [data-type="control_address"] .form-input-wide,
    [data-type="control_email"] .form-input-wide,
    [data-type="control_passwordbox"] .form-input-wide,
    [data-type="control_autocomp"] .form-input-wide {
      width: 100%;
      max-width: 300px;
    }

    [data-type="control_fullname"] .form-sub-label-container {
      box-sizing: border-box;
      width: 48%;
      margin-right: 0;
      float: left;
    }

    [data-type="control_fullname"] .form-sub-label-container:first-child {
      margin-right: 4%;
    }

    [data-type="control_phone"] .form-sub-label-container {
      width: 62.5%;
      margin-left: 2.5%;
      margin-right: 0;
      float: left;
      position: relative;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child {
      width: 32.5%;
      margin-right: 2.5%;
      margin-left: 0;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child [data-component=areaCode] {
      width: 93%;
      float: left;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child [data-component=areaCode] ~ .form-sub-label {
      display: inline-block;
    }

    [data-type="control_phone"] .form-sub-label-container:first-child .phone-separate {
      position: absolute;
      top: 0;
      right: -16%;
      width: 24%;
      text-align: center;
      text-indent: -4px;
    }

    [data-type="control_phone"] .form-sub-label-container .date-separate {
      visibility: hidden;
    }

    .form-matrix-table {
      width: 100%;
      max-width: 300px;
    }

    .form-address-table {
      width: 100%;
      max-width: 300px;
    }

    .form-address-table td .form-dropdown {
      width: 100%;
    }

    .form-address-table td .form-sub-label-container {
      width: 96%;
    }

    .form-address-table td:last-child .form-sub-label-container {
      margin-left: 4%;
    }

    .form-address-table td[colspan="2"] .form-sub-label-container {
      width: 100%;
      margin: 0;
    }

    .form-matrix-row-headers,
    .form-matrix-column-headers,
    .form-matrix-values {
      padding: 4px;
    }

    .form-dropdown {
      border-color: #cccccc;
    }

    [data-type="control_dropdown"] .form-input,
    [data-type="control_birthdate"] .form-input,
    [data-type="control_time"] .form-input,
    [data-type="control_dropdown"] .form-input-wide,
    [data-type="control_birthdate"] .form-input-wide,
    [data-type="control_time"] .form-input-wide {
      width: 100%;
      max-width: 300px;
    }

    [data-type="control_dropdown"] .form-dropdown {
      width: 100% !important;
      max-width: 300px;
    }

    [data-type="control_birthdate"] .form-sub-label-container {
      width: 22%;
      margin-right: 3%;
    }

    [data-type="control_birthdate"] .form-sub-label-container:first-child {
      width: 50%;
    }

    [data-type="control_birthdate"] .form-sub-label-container:last-child {
      margin-right: 0;
    }

    [data-type="control_birthdate"] .form-sub-label-container .form-dropdown {
      width: 100%;
    }

    .form-label {
      font-family: "Didact Gothic", sans-serif;
    }

    li[data-type="control_image"] div {
      text-align: left;
    }

    li[data-type="control_image"] img {
      border: none;
      border-width: 0px !important;
      border-style: solid !important;
      border-color: false !important;
    }

    .form-line-column {
      width: auto;
    }

    .form-line-error {
      background-color: #ffffff;
      box-shadow: inset 0px 3px 11px -7px #ff3200;
    }

    .form-line-error input:not(#coupon-input),
    .form-line-error textarea,
    .form-line-error .form-validation-error {
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -ms-transition-property: none;
      -o-transition-property: none;
      transition-property: none;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -ms-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -ms-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      border: 1px solid #ff3200;
      box-shadow: 0 0 3px #ff3200;
    }

    .form-line-error .form-error-message {
      margin: 0;
      position: absolute;
      color: #fff;
      display: inline-block;
      right: 0;
      font-size: 12px;
      position: absolute !important;
      box-shadow: none;
      top: 0px;
      color: #FFF;
      background: #ff3200;
      padding: 0px 5px;
      bottom: auto;
      border-radius: 0;
      box-shadow: 0px 2px 4px rgba(102, 102, 102, 0.5);
    }

    .form-line-error .form-error-message img,
    .form-line-error .form-error-message .form-error-arrow {
      display: none;
    }

    .form-all {
      position: relative;
    }

    .form-all:before {
      content: "";
      background-image: url("../../../assets/img/H4H/H4H_logo\ \(1\).png");
      display: inline-block;
      height: 130px;
      position: absolute;
      background-size: 230px 110px;
      background-repeat: no-repeat;
      width: 100%;
      margin-top: 20px;
    }

    .form-all {
      margin-top: 160px !important;
    }

    .form-all:before {
      top: -160px;
      background-position: top center;
    }

    .ie-8 .form-all {
      margin-top: auto;
      margin-top: initial;
    }

    .ie-8 .form-all:before {
      display: none;
    }

    [data-type="control_clear"] {
      display: none;
    }

    /* | */
    @media screen and (max-width: 480px), screen and (max-device-width: 767px) and (orientation: portrait), screen and (max-device-width: 415px) and (orientation: landscape) {
      .testOne {
        letter-spacing: 0;
      }

      .form-all {
        border: 0;
        max-width: initial;
      }

      .form-sub-label-container {
        width: 100%;
        margin: 0;
        margin-right: 0;
        float: left;
        box-sizing: border-box;
      }

      span.form-sub-label-container + span.form-sub-label-container {
        margin-right: 0;
      }

      .form-sub-label {
        white-space: normal;
      }

      .form-address-table td,
      .form-address-table th {
        padding: 0 1px 10px;
      }

      .form-submit-button,
      .form-submit-print,
      .form-submit-reset {
        width: 100%;
        margin-left: 0 !important;
      }

      div[id*=at_] {
        font-size: 14px;
        font-weight: 700;
        height: 8px;
        margin-top: 6px;
      }

      .showAutoCalendar {
        width: 20px;
      }

      img.form-image {
        max-width: 100%;
        height: auto;
      }

      .form-matrix-row-headers {
        width: 100%;
        word-break: break-all;
        min-width: 80px;
      }

      .form-collapse-table,
      .form-header-group {
        margin: 0;
      }

      .form-collapse-table {
        height: 100%;
        display: inline-block;
        width: 100%;
      }

      .form-collapse-hidden {
        display: none !important;
      }

      .form-input {
        width: 100%;
      }

      .form-label {
        width: 100% !important;
      }

      .form-label-left,
      .form-label-right {
        display: block;
        float: none;
        text-align: left;
        width: auto !important;
      }

      .form-line,
      .form-line.form-line-column {
        padding: 2% 5%;
        box-sizing: border-box;
      }

      .style-input {

      }

      input[type=text],
      input[type=email],
      input[type=tel],
      textarea {
        width: 100%;
        box-sizing: border-box;
        max-width: 400px !important;
      }

      .form-radio-other-input,
      .form-checkbox-other-input {
        max-width: 55% !important;
      }

      .form-dropdown,
      .form-textarea,
      .form-textbox {
        width: 100% !important;
        box-sizing: border-box;
      }

      .form-input,
      .form-input-wide,
      .form-textarea,
      .form-textbox,
      .form-dropdown {
        max-width: initial !important;
      }

      .form-checkbox-item:not(#foo),
      .form-radio-item:not(#foo) {
        width: 100%;
      }

      .form-address-city,
      .form-address-line,
      .form-address-postal,
      .form-address-state,
      .form-address-table,
      .form-address-table .form-sub-label-container,
      .form-address-table select,
      .form-input {
        width: 100%;
      }

      div.form-header-group {
        padding: 24px 36px !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin: 0 0px 2% !important;
        box-sizing: border-box;
      }

      div.form-header-group.hasImage img {
        max-width: 100%;
      }

      [data-type="control_button"] {
        margin-bottom: 0 !important;
      }

      [data-type=control_fullname] .form-sub-label-container {
        width: 48%;
      }

      [data-type=control_fullname] .form-sub-label-container:first-child {
        margin-right: 4%;
      }

      [data-type=control_phone] .form-sub-label-container {
        width: 65%;
        margin-right: 0;
        margin-left: 0;
        float: left;
      }

      [data-type=control_phone] .form-sub-label-container:first-child {
        width: 31%;
        margin-right: 4%;
      }

      [data-type=control_datetime] .allowTime-container {
        width: 100%;
      }

      [data-type=control_datetime] .allowTime-container .form-sub-label-container {
        width: 24% !important;
        margin-left: 6%;
        margin-right: 0;
      }

      [data-type=control_datetime] .allowTime-container .form-sub-label-container:first-child {
        margin-left: 0;
      }

      [data-type=control_datetime] span + span + span > span:first-child {
        display: block;
        width: 100% !important;
      }

      [data-type=control_birthdate] .form-sub-label-container,
      [data-type=control_time] .form-sub-label-container {
        width: 27.3% !important;
        margin-right: 6% !important;
      }

      [data-type=control_time] .form-sub-label-container:last-child {
        width: 33.3% !important;
        margin-right: 0 !important;
      }

      .form-pagebreak-back-container,
      .form-pagebreak-next-container {
        min-height: 1px;
        width: 50% !important;
      }

      .form-pagebreak-back,
      .form-pagebreak-next,
      .form-product-item.hover-product-item {
        width: 100%;
      }

      .form-pagebreak-back-container {
        padding: 0;
        text-align: right;
      }

      .form-pagebreak-next-container {
        padding: 0;
        text-align: left;
      }

      .form-pagebreak {
        margin: 0 auto;
      }

      .form-buttons-wrapper {
        margin: 0 !important;
        margin-left: 0 !important;
      }

      .form-buttons-wrapper button {
        width: 100%;
      }

      .form-buttons-wrapper .form-submit-print {
        margin: 0 !important;
      }

      table {
        width: 100% !important;
        max-width: initial !important;
      }

      table td + td {
        padding-left: 3%;
      }

      .form-checkbox-item,
      .form-radio-item {
        white-space: normal !important;
      }

      .form-checkbox-item input,
      .form-radio-item input {
        width: auto;
      }

      .form-collapse-table {
        margin: 0 5%;
        display: block;
        zoom: 1;
        width: auto;
      }

      .form-collapse-table:before,
      .form-collapse-table:after {
        display: table;
        content: '';
        line-height: 0;
      }

      .form-collapse-table:after {
        clear: both;
      }

      .fb-like-box {
        width: 98% !important;
      }

      .form-error-message {
        clear: both;
        bottom: -10px;
      }

      .date-separate,
      .phone-separate {
        display: none;
      }

      .custom-field-frame,
      .direct-embed-widgets,
      .signature-pad-wrapper {
        width: 100% !important;
      }
    }

    /* | */

    /*__INSPECT_SEPERATOR__*/
    #header_1 {
      font-weight: bold !important;
    }

    #subHeader_1 {
      color: white;
      padding-top: 15px;
    }

    .form-label.form-label-auto {
      display: inline-block;
      float: left;
      text-align: left;
    }

    .form-sub-label {
      font-weight: bold !important;
    }

    option {
      padding: 5px;
    }

    input, input[type=text], select {
      border-radius: 0px !important;
    }

    th.col1.translatable {
      display: none !important;
    }

    th.col2.translatable {
      display: none !important;
    }

    input[type="file"] {
      display: none;
    }

    .custom-file-upload {
      cursor: pointer;
      color: #ffffff !important;
      background: #31e4a9;
      box-shadow: none;
      text-shadow: none;
      font-size: 1.15em;
      padding: 12px 18px;
      display: block;
      max-width: 200px;
      margin: 0;
      font-family: "Didact Gothic", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border: none;
      border-width: 0px !important;
      border-style: solid !important;
      border-color: #31e4a9 !important;
      position: relative;
      overflow: hidden;
      direction: ltr;
      text-align: center;
      border-radius: 7px;
    }

    .qq-upload-fail {
      background: #31e4a9 !important;
      color: #fff !important;
      display: inline !important;
    }

    .qq-upload-failed-text {
      display: none !important;
    }

    /* Injected CSS Code */
  </style>
</head>
<body>
<form style="padding-top: 95px" class="jotform-form"
      action="https://postulaciones.dsindigo.com:8444/create-postulante" method="post"
      enctype="multipart/form-data" name="form_212136949899070" id="212136949899070" accept-charset="utf-8"
      autocomplete="on">
  <input type="hidden" name="formID" value="212136949899070"/>
  <input type="hidden" id="JWTContainer" value=""/>
  <input type="hidden" id="cardinalOrderNumber" value=""/>
  <div role="main" class="form-all">
    <ul class="form-section page-section">
      <li id="cid_1" class="form-input-wide" data-type="control_head">
        <div class="form-header-group  header-default">
          <div class="header-text httac htvam">
            <h2 id="header_1" class="form-header" data-component="header">
              ¡Envíanos tu C.V!
            </h2>
            <div id="subHeader_1" class="form-subHeader">
              H4H te invita a formar parte de su equipo, compártenos tu información y nos pondremos en contacto
              contigo.
            </div>
          </div>
        </div>
      </li>
      <li class="form-line jf-required" data-type="control_textbox" id="id_26">
        <label class="form-label form-label-left form-label-auto" id="label_26" for="input_26">
          Nombre
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_26" class="form-input jf-required">
          <input type="text" style="font-family:'Didact Gothic'!important;" id="input_26" name="q26_nombre26"
                 data-type="input-textbox" class="form-textbox validate[required]" size="20" value=""
                 data-component="textbox" aria-labelledby="label_26" required=""/>
        </div>
      </li>
      <li class="form-line" data-type="control_dropdown" id="id_19">
        <label class="form-label form-label-left form-label-auto" id="label_19" for="input_19"> ¿Cómo te enteraste de
          esta vacante? </label>
        <div id="cid_19" class="form-input">
          <select class="form-dropdown" id="input_19" name="q19_comoTe19"
                  style="width:150px; font-size: 18px!important; font-family:'Didact Gothic'!important;"
                  data-component="dropdown">
            <option selected="" value="1"> Linkedln</option>
            <option value="2"> Facebook</option>
            <option value="3"> Twitter</option>
            <option value="4"> OCC</option>
            <option value="5"> Feria de reclutamiento</option>
            <option value="6"> Otro</option>
          </select>
        </div>
      </li>

      <li class="form-line" data-type="control_textbox" id="id_10">
        <label class="form-label form-label-left form-label-auto" id="label_10" for="input_10"> Puesto
          solicitado </label>
        <div id="cid_10" class="form-input">
          <input  type="hidden" style="font-family:'Didact Gothic'!important;" id="input_10" name="q10_puestoSolicitado"
                  data-type="input-textbox" class="form-textbox" size="20"
                  data-component="textbox" value="{{postulationUser.toString()}}"
                  aria-labelledby="label_10"/>

          <input  type="hidden" style="font-family:'Didact Gothic'!important;" id="input_40" name="q40_idPuestoSolicitado"
                  data-type="input-textbox" class="form-textbox" size="20"
                  data-component="textbox" value="{{postulationIdVacant.toString()}}"
                  aria-labelledby="label_40"/>
          <div class="box-options container-over-y-p">

            <div *ngIf="!load">
              <div class="mx-auto text-center">
                <p>Cargando vacantes...</p>
                <div>
                  <img src="./assets/svg/loading.svg" style="width: 55px; height: 55px;
                    margin-top: -15px" alt="">
                </div>
              </div>
            </div>

            <div *ngFor="let item of ArrayVacant; let i = index">
              <div class="form-check" *ngIf="item['nombre-del-puesto'] == vacantPostulation">
                <input (click)="addPostulation(item, 'flexCheckDefault'+i)"
                       class="form-check-input" type="checkbox" value="" id="flexCheckDefault{{i}}" checked>
                <label class="form-check-label" for="flexCheckDefault{{i}}" style="font-size: 14px">
                  {{item['nombre-del-puesto']}}
                </label>
              </div>
            </div>


            <div *ngFor="let item of ArrayVacant; let j = index">
              <div class="form-check" *ngIf="item['nombre-del-puesto'] != vacantPostulation"
              >
                <input (click)="addPostulation(item, ('flexCheckChecked'+ j))"
                       class="form-check-input" type="checkbox" value="" id="flexCheckChecked{{j}}">
                <label class="form-check-label" for="flexCheckChecked{{j}}" style="font-size: 14px">
                  {{item['nombre-del-puesto']}}
                </label>
              </div>
            </div>
          </div>


          <label for="input_10" style="font-size: 12px" class="bold">
            Puedes seleccionar más de una vacante
          </label>

        </div>
      </li>


      <li class="form-line jf-required" data-type="control_email" id="id_15"
          style="margin-bottom: 40px; margin-top: 40px;">
        <label class="form-label form-label-left form-label-auto" id="label_15" for="input_16">
          Sueldo solicitado
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_15" class="form-input jf-required">
          <span class="form-sub-label-container"
                style="vertical-align:top; flex-direction: row; align-items: center; align-content: center;">
            <select name="input_sueldo_type" id="type_sueldo_id"
                    style="font-family:'Didact Gothic'!important; font-size:18px;height: 40px; border-radius: 3px!important; outline: none!important; width:80px; border: 1px solid #ccc; padding: 5px!Important;">
              <option value="MXN">MXN</option>
            </select>
            <input type="number" pattern="[0-9]+" id="input_15"
                   style="max-width: 320px!important;margin-left: 7px; font-family:'Didact Gothic'!important;"
                   name="q15_sueldoSolicitad" class="form-textbox validate[required]" size="30" value=""
                   data-component="email" aria-labelledby="label_15 sublabel_input_15" required="required">
          </span>
        </div>
      </li>


      <li class="form-line jf-required" data-type="control_email" id="id_16" style="margin-bottom: 40px;">
        <label class="form-label form-label-left form-label-auto" id="label_16" for="input_16">
          Correo electrónico
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_16" class="form-input jf-required">

          <div class="form-sub-label-container input-form-data-email" id="form-data-email">
            <select name="input_email_type_0" id="type_email_id" class="detail-form">
              <option value="home">Home</option>
              <option value="work">Work</option>
              <option value="other">Other</option>
            </select>
            <input type="email" id="input_16"
                   style="max-width: 320px!important;margin-left: 7px ;font-family:'Didact Gothic'!important;"
                   name="q16_correoElectronico_0" class="form-textbox validate[required, Email] add-text-style fix"
                   size="30" value="" data-component="email" aria-labelledby="label_16 sublabel_input_16" required="">
          </div>
          <button onclick="add()" type="button" class="add-anoter"
                  style="cursor: pointer;background-color: #31e4a9; border: none!important; color: white; font-weight: bold; font-family:'Didact Gothic'!important; padding: 12px; border-radius: 7px; margin-top: 7px;">
            Add another
          </button>
          <button onclick="deleteValue()" id="btn-delete" type="button" class="delete"
                  style="display:none; margin-left: 15px; cursor: pointer;background-color: #ff3200; border: none!important; color: white; font-weight: bold; font-family:'Didact Gothic'!important; padding: 12px; border-radius: 7px; margin-top: 7px;">
            delete
          </button>
        </div>
      </li>

      <li class="form-line jf-required" data-type="control_phone" id="id_35" data-compound-hint=","
          style="width: 100%!important; margin-bottom: 40px;">
        <label class="form-label form-label-left form-label-auto" id="label_35" for="input_35_area">
          Número de teléfono
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_35" class="form-input jf-required">
          <div data-wrapper-react="true">
            <div id="form-data-tel">
              <span class="form-sub-label-container" style="vertical-align:top; margin-right: -43px!important;"
                    data-input-type="areaCode">
                <select name="input_tel_type_0" id="type_tel_id"
                        style="font-family:'Didact Gothic'!important; font-size:18px;height: 40px; border-radius: 3px!important; outline: none!important; width:80px; border: 1px solid #ccc; padding: 5px!Important;">
                  <option value="work">Work</option>
                  <option value="mobile">Mobile</option>
                  <option value="home">Home</option>
                  <option value="main">Main</option>
                  <option value="private fax">Private Fax</option>
                  <option value="other">Other</option>
                </select>
              </span>
              <span class="" style="vertical-align:top; margin-left: 5px;" data-input-type="phone">
                <input type="number" pattern="[0-9]+" id="input_35_phone"
                       style="max-width: 320px!important; margin-left: -8px;" name="q35_numeroDe_0"
                       class="form-textbox validate[required] fix-mobile" value="" data-component="phone"
                       aria-labelledby="label_35 sublabel_35_phone" required="">
              </span>
            </div>

            <div class="flex-d">
              <button onclick="addTel()" type="button" class="add-anoter-tel"
                      style="cursor: pointer;background-color: #31e4a9; border: none!important; color: white; font-weight: bold; font-family:'Didact Gothic'!important; padding: 12px; border-radius: 7px; margin-top: 7px;">
                Add another
              </button>
              <button onclick="deleteValueTel()" id="btn-delete-tel" type="button" class="delete"
                      style="width:60px; margin-left: 15px ; display:none; cursor: pointer;background-color: #ff3200; border: none!important; color: white; font-weight: bold; font-family:'Didact Gothic'!important; padding: 12px; border-radius: 7px; margin-top: 7px;">
                delete
              </button>
            </div>


            <style>
              .add-text-style {
                font-family: "Didact Gothic" !important;
              }
            </style>


          </div>
        </div>
      </li>


      <li class="form-line jf-required" data-type="control_textbox" id="id_21">
        <label class="form-label form-label-left form-label-auto" id="label_21" for="input_21">
          Lugar de residencia
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_21" class="form-input jf-required">
          <span class="form-sub-label-container" style="vertical-align:top">
            <input type="text" style="font-family:'Didact Gothic'!important;" id="input_21" name="q21_lugarDe"
                   data-type="input-textbox" class="form-textbox validate[required]" size="20" value=""
                   data-component="textbox" aria-labelledby="label_21 sublabel_input_21" required=""
                   placeholder="Ciudad y país"/>
          </span>
        </div>
      </li>
      <li class="form-line jf-required" data-type="control_dropdown" id="id_22" style="margin-top: 35px;">
        <label class="form-label form-label-left form-label-auto" id="label_22" for="input_22">
          Esquema de trabajo preferido
        </label>
        <div id="cid_22" class="form-input jf-required">
          <select class="form-dropdown validate[required]" id="input_22" name="q22_esquemaDe"
                  style="width:150px; font-size: 18px!important; font-family:'Didact Gothic'!important;"
                  data-component="dropdown" required="">
            <option value="1"> Presencial</option>
            <option value="2"> Remoto</option>
            <option value="3"> Mixto</option>
          </select>
        </div>
      </li>
      <li class="form-line jf-required" data-type="control_fileupload" id="id_18">
        <label class="form-label form-label-left form-label-auto" id="label_18" for="input_18">
          Sube tu C.V
          <span class="form-required">
            *
          </span>
        </label>
        <div id="cid_18" class="form-input jf-required">
          <div data-wrapper-react="true">
            <span class="form-sub-label-container" style="vertical-align:top">
              <label class="qq-uploader-buttonText-value custom-file-upload">
                <input type="file" oninput="validation()" id="input_18" name="cv" class="validate[required]"
                       data-imagevalidate="yes" data-file-accept="pdf" data-file-maxsize="10854" data-file-minsize="0"
                       data-file-limit="" data-component="fileupload" accept="application/pdf" required=""/>
              Buscar archivos
            </label>
              <label class="form-sub-label" for="input_18" style="min-height:13px"
                     aria-hidden="false"> Seleccionar tu C.V (requerido)</label>
              <div>
                <div id="display-info">
                  <p id="filename_data" class="name-data-input">
                    Name file
                  </p>
                  <!--<button type="button" class="btn-delete" onclick="delete_item()">x</button>-->
                </div>
                <style>
                  #display-info {
                    display: none;
                  }

                  .name-data-input {
                    background-color: #31e4a9;
                    padding: 3px;
                    border-radius: 5px;
                    color: white;
                    max-width: 260px;
                    font-size: 14px;
                  }

                  .btn-delete {
                    background-color: #e67154;
                    color: white;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                  }
                </style>
              </div>
            </span>
            <span style="display:none" class="cancelText">
              Cancel
            </span>
            <span style="display:none" class="ofText">
              of
            </span>
          </div>
        </div>
      </li>
      <li class="form-line jf-required" data-type="control_captcha" id="id_20">
        <label class="form-label form-label-left" id="label_20" for="input_20">
          <span class="form-required">
          </span>
        </label>
        <div id="cid_20" class="form-input jf-required">
          <section data-wrapper-react="true">
            <div id="recaptcha_input_20" data-component="recaptcha" data-callback="recaptchaCallbackinput_20"
                 data-expired-callback="recaptchaExpiredCallbackinput_20">
            </div>
            <input type="hidden" id="input_20" class="hidden validate[required]" name="recaptcha_visible" required=""/>

          </section>
        </div>
      </li>
      <li class="form-line" data-type="control_button" id="id_2" >
        <div id="cid_2" class="form-input-wide">
          <div style="margin-left:156px; justify-content: center" data-align="auto"
               class="form-buttons-wrapper form-buttons-auto   jsTest-button-wrapperField">
            <button (click)="verify()" id="input_2" style="background-color: #7c28de !important; border-radius: 25px!important; height: 45px;"
                    class="form-submit-button submit-button jf-form-buttons jsTest-submitField" data-component="button"
                    data-content="">
              <h5 style="font-weight: 600;">Aplicar</h5>
            </button>
          </div>
        </div>
      </li>
      <li class="form-line" data-type="control_widget" id="id_25">
        <label class="form-label form-label-left form-label-auto" id="label_25" for="input_25"> </label>
        <div id="cid_25" class="form-input">
          <div data-widget-name="Seguimiento Social" style="width:100%;text-align:Left;overflow-x:auto"
               data-component="widget-field">
            <iframe data-client-id="529260f705a7114f70000008" title="Seguimiento Social" frameBorder="0" scrolling="no"
                    allowtransparency="true"
                    allow="geolocation; microphone; camera; autoplay; encrypted-media; fullscreen" data-type="iframe"
                    class="custom-field-frame" id="customFieldFrame_25" src=""
                    style="max-width:600px;border:none;width:100%;height:40px" data-width="600" data-height="40">
            </iframe>
            <div class="widget-inputs-wrapper">
              <input type="hidden" id="input_25" class="form-hidden form-widget  " name="q25_input25" value=""/>
              <input type="hidden" id="widget_settings_25" class="form-hidden form-widget-settings"
                     value="%5B%7B%22name%22%3A%22facebook%22%2C%22value%22%3A%22https%3A%2F%2Fwww.facebook.com%2FIndigoIT%22%7D%2C%7B%22name%22%3A%22twitter%22%2C%22value%22%3A%22https%3A%2F%2Ftwitter.com%2FIndigo_IT%22%7D%2C%7B%22name%22%3A%22linkedin%22%2C%22value%22%3A%22https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Findigo-smart-software-development%2F%22%7D%2C%7B%22name%22%3A%22instagram%22%2C%22value%22%3A%22https%3A%2F%2Fwww.instagram.com%2Findigo_it%22%7D%2C%7B%22name%22%3A%22customCSS%22%2C%22value%22%3A%22.sbuttons%20%20%7B%5Cn%20border-radius%3A%2035%25%3B%5Cn%20margin-right%3A%2010px%5Cn%7D%5Cn%5Cn.social-list%20.sbuttons.facebook%20%7B%5Cn%20%20%20%20background-image%3A%20url(https%3A%2F%2Fsimpleicons.org%2Ficons%2Ffacebook.svg)%3B%5Cn%7D%5Cn%5Cn.social-list%20.sbuttons.twitter%7B%5Cn%20%20%20%20background-image%3A%20url(https%3A%2F%2Fsimpleicons.org%2Ficons%2Ftwitter.svg)%3B%5Cn%7D%5Cn%5Cn.social-list%20.sbuttons.linkedin%7B%5Cn%20%20%20%20background-image%3A%20url(https%3A%2F%2Fsimpleicons.org%2Ficons%2Flinkedin.svg)%3B%5Cn%7D%5Cn%5Cn.social-list%20.sbuttons.instagram%20%7B%5Cn%20%20%20%20background-image%3A%20url(https%3A%2F%2Fsimpleicons.org%2Ficons%2Finstagram.svg)%3B%5Cn%7D%22%7D%5D"
                     data-version="2"/>
            </div>

          </div>
        </div>
      </li>
      <li style="display:none">
        Should be Empty:
        <input type="text" name="website" value=""/>
      </li>
    </ul>
  </div>
  <script>
    JotForm.showJotFormPowered = "0";
  </script>
  <script>
    JotForm.poweredByText = "Desarrollado por JotForm";
  </script>
  <input type="hidden" class="simple_spc" id="simple_spc" name="simple_spc" value="212136949899070"/>
</form>

</body>
</html>


</body>
