import { Component, OnInit } from '@angular/core';
import {PodioService} from '../../api/podio.service';

@Component({
  selector: 'app-postulation',
  templateUrl: './postulation.component.html',
  styleUrls: ['./postulation.component.scss']
})
export class PostulationComponent implements OnInit {

  vacantPostulation: string | null;
  ArrayVacant = []
  postulationIdVacant = [];
  postulationUser: any;
  load: boolean;

  constructor(
    private _podioService: PodioService,
  ) {
    this.vacantPostulation = '';
    this.load = false;
    this.postulationUser  = [];
  }

  ngOnInit(): void {
    this.getVacantPostulation();
    this.getVacant();
  }


  getVacantPostulation() {
    this.vacantPostulation = localStorage.getItem('vacant');
    this.postulationUser.push(this.vacantPostulation);
  }

  getVacant() {
    this._podioService.getProducts().subscribe(
      response => {
        this.saveVacant(response)
        this.load = true;
      }
    )
  }

  saveVacant(response: any) {
    this.ArrayVacant = response;
    response.forEach((e : any) => {
      if (e['nombre-del-puesto'] == this.vacantPostulation) {
        // @ts-ignore
        this.postulationIdVacant.push(e['item_id']);
        console.log(this.postulationIdVacant);
      }
    });
  }

  addPostulation(data:any, action:any) {
    let actionCheckbox = document.getElementById(action);
    // @ts-ignore
    if (actionCheckbox.checked) {
      this.postulationUser.push(data['nombre-del-puesto']);
      // @ts-ignore
      this.postulationIdVacant.push(data['item_id']);
    } else {
      const index = this.postulationUser.indexOf(data['nombre-del-puesto']);
      if (index > -1) {
        this.postulationUser.splice(index, 1);
        this.postulationIdVacant.splice(index, 1);
      }
    }
    console.log(this.postulationIdVacant);
  }
  verify() {
    console.log('ENTRY');
    // @ts-ignore
    document.getElementById('212136949899070').submit();
    console.log('SUCCESS');
    return false;
  }
}
