import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './component/home/home.component';
import {PostulationComponent} from './component/postulation/postulation.component';
import {ViewVacantComponent} from './component/view-vacant/view-vacant.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'postulation',
    component: PostulationComponent
  },
  {
    path: 'viewVacant/:id',
    component: ViewVacantComponent
  },
  {path: '', component: HomeComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
