<body class="bkg-indigo" style="width: 100%; min-height: 100vh!important">
<app-navbar class="fixed-top"></app-navbar>
<section style="padding-top: 95px">
  <div class="container mx-auto bkg-header-home">
    <h1 class="text-white text-center"  style="padding-top: 15px; font-weight: 700;">Conoce nuestras <span class="secundary-color">vacantes</span></h1>
  </div>
</section>

<section>
  <div *ngIf="load">
    <div class="cover-f fix-w" style="margin-top: 60px">
      <div class="container-over-y-p mx-auto" style="max-width: 1500px!important;">
        <section class="flex-c fix-w justify-content-center" style="width: auto!important;">

          <div *ngFor="let vacant of ArrayVacant; let i = index">
            <div class="test-box" *ngIf="vacant['estatus-vacante'] == 'Abierta'">
              <div>
                <img *ngIf="!vacant['url-imagen']"
                     src="https://modernadmin.evolvision.com/assets/images/carousel/06.jpg" alt="" class="img-flow"
                     style="width: 100%; height: 300px" loading="lazy">
                <img *ngIf="vacant['imagen']" src="{{vacant['url-imagen'][0]['embed']['original_url']}}"
                     alt="{{vacant['url-imagen']}}" class="img-flow"
                     style="width: 100%; height: 300px" loading="lazy">
              </div>
              <div class="container" style="margin-top: 20px">
                <div class="flex-d">
                  <p class="bold h4 fix-text" style="text-align: left" [title]="vacant['nombre-del-puesto']">{{vacant['nombre-del-puesto']}}</p>
                  <div class="btn-share share-node">
                    <div class="c-share" (click)="getVacantIdEmail(vacant['item_id'])">
                      <input class="c-share__input" type="checkbox" id="checkbox{{i}}">
                      <label class="c-share__toggler" for="checkbox{{i}}">
                        <img src="./assets/svg/share_1.svg" alt=""
                             style="width: 25px; height: 25px; margin-left: -15px; margin-top: 10px; color: pink!important">
                      </label>
                      <ul class="c-share_options" data-title="Compartir">
                        <li>
                          <img src="./assets/svg/facebook.svg" alt="" class="icon-share">
                          <a style="color: black; text-decoration: none!important"
                             href="https://www.facebook.com/sharer/sharer.php?u=https://postulaciones.dsindigo.com/thank-you.html"
                             target="_blank">
                            Facebook
                          </a>
                        </li>
                        <li>
                          <img src="./assets/svg/twitter.svg" alt="" class="icon-share">
                          <a style="color: black; text-decoration: none!important"
                             href="https://twitter.com/intent/tweet?text=Una%20gran%20oportunidad%20en%20Indigo%20-%20Vacante%20Disponible%20Tenemos%20una%20vacante%20disponible,%20conocela%20aqui:%20{{urlServer}}/viewVacant/{{idEmailVacant}}"
                             target="_blank">
                            Twitter
                          </a>
                        </li>
                        <li class="text-dark" data-bs-toggle="modal" data-bs-target="#search">
                          <img src="./assets/svg/email.svg" alt="" class="icon-share">
                          <a style="color: black; text-decoration: none!important">
                            Email
                          </a>
                        </li>
                        <li (click)="copyClipboard(i)" id="{{i}}">
                          <a style="color: black; text-decoration: none!important">
                            Copiar link <strong>{{textCopy}}</strong>
                          </a>
                          <input type="text" style="border: none!important" disabled
                                 value="{{urlServer}}/viewVacant/{{idEmailVacant}}">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="text-length" style="min-height: 48px!important">{{parserHTML(vacant['mision-del-puesto'])}}</div>
              </div>
              <div class="text-center" style="margin-top: 25px!important">
                <button class="btn btn-bkg" type="button" routerLink="/viewVacant/{{vacant['item_id']}}"
                >Conocer más
                </button>
              </div>
            </div>
            <br>
            <br>
            <br>
          </div>
        </section>
      </div>
    </div>
  </div>

  <app-spinner *ngIf="!load" [text]="textLoad" [subtext]="subtextLoad"></app-spinner>
  <br>
</section>

<!-- SECTION MODAL HIDDEN DETAIL ABOUT JOB -->


<!-- SECTION MODAL HIDDEN DETAIL ABOUT SEND EMAIL A FRIEND -->

<section>
  <div class="modal fade" id="search" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content color-search mx-auto text-center" style="padding: 10px">
        <p class="h3 text-dark mb-3">Enviar vacante a un amigo por correo</p>
        <p class="h6 text-dark fw-light mb-3">Ingresa el email de un amigo que pueda interesarle esta vacante</p>
        <form>
          <input (input)="getEmail($event.target)" type="email" class="email-panel"
                 placeholder="Ingresa el email de un amigo">
        </form>
        <a class="btn bkg-item text-light text-center mx-auto" style="width: 220px; text-decoration: none!important;
        background-color: #3468ee; margin-top: 25px; color: white!important"
           href="mailto:{{email}}?subject=Una%20gran%20oportunidad%20en%20Indigo%20-%20Vacante%20Disponible&body=Tenemos%20una%20vacante%20disponible,%20conocela%20aqui:%20{{urlServer}}/viewVacant/{{idEmailVacant}}">
          Enviar
        </a>
      </div>
    </div>
  </div>
</section>

</body>
