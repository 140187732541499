import { Component, OnInit } from '@angular/core';
import {$} from 'protractor';
import {PodioService} from '../../api/podio.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  ArrayVacant = []
  VacantIDOBJ: any;
  email: string;
  idEmailVacant: any;
  load: boolean;
  urlServer: string;
  textCopy: string;

  textLoad: string;
  subtextLoad: string;

  constructor(
    private _podioService: PodioService,
    private _router: Router
  ) {
    this.email = '';
    this.load = false;
    this.urlServer = window.location.origin;
    this.textCopy = '';
    this.textLoad = 'Cargando vacantes';
    this.subtextLoad = 'disponibles';
  }

  ngOnInit(): void {
    this.getVacant();
  }

  getVacant() {
    this._podioService.getProducts().subscribe(
      response => {
        // @ts-ignore
        this.saveVacant(response);
        console.log(response);
        this.load = true;
      }
    )
  }

  saveVacant(response: any) {
    this.ArrayVacant = response;
  }

  vacantID(value:any) {
    this.VacantIDOBJ = value;
    window.location.href = '/viewVacant/' + this.VacantIDOBJ['item_id'];
  }

  parserHTML(value: string) {
    let data = value.split('<p>');
    try {
      data = data[1].split('</p>')
    } catch (e) {

    }
    return data[0];
  }

  applyVacant(value:string) {
    localStorage.setItem('vacant', value);
    window.location.href = '/#/postulation';
  }

  getEmail(value: any) {
      this.email = value.value;
  }

  getVacantIdEmail(value: any) {
    this.idEmailVacant = value;
  }

  copyClipboard(i:any) {
    var textArea = document.createElement("textarea");
    textArea.value = this.urlServer +'/#/viewVacant/' + this.idEmailVacant;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
    }

    this.textCopy = '(copiado)';
    setTimeout(()=>{
      this.textCopy = '';
    }, 3000);

    document.body.removeChild(textArea);
  }

}
