import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PodioService {

  public GLOBAL: string;

  constructor(public _http: HttpClient) {
    this.GLOBAL = 'https://postulaciones.dsindigo.com:8444/';
  }


  getProducts() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.GLOBAL + 'vacants', {headers});
  }


}
