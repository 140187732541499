<body class="bkg-indigo" style="width: 100%; min-height: 100vh!important">
<app-navbar></app-navbar>
<div class=" container bkg-second text-light" tabindex="-1" id="data-project"
     aria-labelledby="offcanvasBottomLabel" style="margin-top: 45px">

  <div *ngIf="!load">
    <div class="text-center" style="padding-top: 125px">
      <p class="text-white h1">Cargando la información de la <strong class="secundary-color">vacante...</strong></p>
      <div>
        <img src="./assets/svg/loading.svg" style="width: 125px; height: 125px" alt="">
      </div>
    </div>
  </div>
  <br>


  <div class="">
    <p class="h2 fw-lighter  text-center" style="font-size: 40px; justify-content: center; padding-top: 35px">
      <strong class="bold secundary-color" *ngIf="VacantIDOBJ['nombre-del-puesto']">Vacante:</strong> {{VacantIDOBJ['nombre-del-puesto']}}
    </p>
  </div>

  <div class="offcanvas-body small mx-auto" *ngIf="VacantIDOBJ">
    <div class="w-100 mx-auto container">
      <div class="text-canvas p-3">

        <img *ngIf="!VacantIDOBJ['url-imagen']"
             src="https://modernadmin.evolvision.com/assets/images/carousel/06.jpg" alt="" class="img-flow"
             style="width: 100%; max-height: 400px">
        <img *ngIf="VacantIDOBJ['imagen']" src="{{VacantIDOBJ['url-imagen'][0]['embed']['original_url']}}"
             alt="{{VacantIDOBJ['url-imagen']}}" class="img-flow"
             style="width: 100%; max-height: 400px">

        <div class="w-100 flex-d" style="margin-top: 45px!important" *ngIf="VacantIDOBJ['anos-de-experiencia-2']">
          <p class="h4 m-t-15 secundary-color">Años de experiencia:</p>
          <div class="bkg-sub-data">
            <h5>{{VacantIDOBJ['anos-de-experiencia-2']}}</h5>
          </div>
        </div>

        <div class="w-100 flex-d" style="margin-top: 45px!important">
          <p class="h4 m-t-15 secundary-color">Idioma:</p>
          <div>
            <h5 class="bkg-item">{{VacantIDOBJ['idioma'] + ' (' + VacantIDOBJ['nivel'] + ')' }}</h5>
          </div>
        </div>

        <div style="margin-top: 35px">
          <div style="margin-top: 25px">
            <p class="h4 secundary-color">Objetivo:</p>
            <div style="text-align: justify" id="missionData" class="h5 bkg-sub-data">
              <p>
                {{parserHTML(VacantIDOBJ['mision-del-puesto'], 'missionData')}}
              </p>
            </div>
          </div>

          <div style="margin-top: 25px" *ngIf="VacantIDOBJ['esquema-laboral']">
            <p class="h4 secundary-color">Esquema laboral:</p>
            <div style="text-align: justify" id="schemaData" class="h5 bkg-sub-data">
              <p>
                {{parserHTML(VacantIDOBJ['esquema-laboral'], 'schemaData')}}
              </p>
            </div>
          </div>

          <div style="margin-top: 25px">
            <p class="h4 secundary-color">Conocimiento y/o Habilidades Técnicas:</p>
            <div style="text-align: justify" id="skillnsData" class="h5 bkg-sub-data">
              <p>
                {{parserHTML(VacantIDOBJ['conocimiento-yo-habilidades-tecnicas'], 'skillnsData')}}
              </p>
            </div>
          </div>

          <div style="margin-top: 25px">
            <p class="h4 secundary-color">Principales Funciones / Responsabilidades:</p>
            <div style="text-align: justify" id="functionsData" class="h5 bkg-sub-data">
              <p>
                {{parserHTML(VacantIDOBJ['principales-funciones-responsabilidades'], 'functionsData')}}
              </p>
            </div>
          </div>

        </div>


        <div class="text-center" style="margin-top: 45px;">
          <a class="btn btn-bkg" (click)="applyVacant(VacantIDOBJ['nombre-del-puesto'])">
            <h5 style="font-weight: 600;">Aplicar</h5>
          </a>
        </div>
      </div>
      <div>
      </div>


    </div>
  </div>
</div>


</body>
