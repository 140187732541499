import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PodioService} from '../../api/podio.service';

@Component({
  selector: 'app-view-vacant',
  templateUrl: './view-vacant.component.html',
  styleUrls: ['./view-vacant.component.scss']
})
export class ViewVacantComponent implements OnInit {

  idVacant: string | null;
  ArrayVacant = []
  VacantIDOBJ: any;
  load: boolean;
  textLoad: string;
  subtextLoad: string;

  constructor(
    private route: ActivatedRoute,
    private _podioService: PodioService,
  ) {
    this.idVacant = '';
    this.load = false;
    this.textLoad = 'Cargando la información de la';
    this.subtextLoad = 'vacante';
  }

  ngOnInit(): void {
    this.idVacant = this.route.snapshot.paramMap.get('id');
    this.getVacant();
  }

  getVacant() {
    this._podioService.getProducts().subscribe(
      response => {
        this.saveVacant(response)
        this.load = true;
      }
    )
  }

  saveVacant(response: any) {
    this.ArrayVacant = response;
    this.getVacantById();
  }

  parserHTML(value: string, id:string) {
    var el = document.createElement( 'html' );
    el.innerHTML = value;
    let index = document.getElementById(id);
    // @ts-ignore
      index.innerHTML = el.innerHTML;
  }

  applyVacant(value:string) {
    localStorage.setItem('vacant', value);
    window.location.href = '/#/postulation';
    location.reload();
  }

  getVacantById() {
    this.ArrayVacant.forEach(
      item => {
        if(item['item_id'] == parseInt(<string> this.idVacant)) {
          this.VacantIDOBJ = item;
        }
      }
    )
  }

}
