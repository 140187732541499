<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://www.dsindigo.com/" target="_blank">
      <img src="./assets/img/H4H/H4H_logo (1).png" alt="" width="140" height="60">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" style="color: white!important"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white" routerLink="#"><h3 class="custom-title">Vacantes</h3></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
