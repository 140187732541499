import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { PostulationComponent } from './component/postulation/postulation.component';
import { NavbarComponent } from './structure/navbar/navbar.component';
import { ViewVacantComponent } from './component/view-vacant/view-vacant.component';
import {PodioService} from './api/podio.service';
import {HttpClientModule} from '@angular/common/http';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './structure/spinner/spinner.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PostulationComponent,
    NavbarComponent,
    ViewVacantComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMultiSelectModule,
    FormsModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    PodioService,
    HttpClientModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
